/* eslint-disable react/display-name */
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components';
import Stepper from '../components/Stepper/Stepper';
import FacilityDialog from 'components/FacilitySearchDialog';
import { useHistory, useLocation } from 'react-router-dom';
import { RepairInformationStep } from './QuotedRepairs/components/RepairInformationStep';
import { AddressInformationStep } from './QuotedRepairs/components/AddressInformationStep';
import { ContactInformationStep } from './QuotedRepairs/components/ContactInformationStep';
import { EvaluationFeeStep } from './QuotedRepairs/components/EvaluationFeeStep';
import { useOems } from '../hooks/useOems'
import { PreviewBeforeRequestStep } from './QuotedRepairs/components/PreviewBeforeRequestStep';
import { useDispatch, useSelector } from 'react-redux';
import { saveUserReduxState } from './../store/User';
import {getCompanyAndVendorFields, getPartCondition, getPurchaseChoice} from 'productUtility';
import {getCookie} from 'utility';
import axios from 'axios';
import AddToCartConfirmation from '../components/AddToCartConfirmation';
import {actionCreators as cartActions} from '../store/Cart'


const MainContainer = styled.div`
`;

const Header = styled.div`
    text-align: center;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 30px;

    h1 {
        font-size: 30px;
        font-weight: 300 !important;
        font-family: Source Sans Pro, sans-serif;
        max-width: 100%;
    }

    img {
        width: 40px;
        height: 40px;
        margin-bottom: 16px;
    }

    @media (max-width: 768px) {
        h1 {
            font-size: 24px;
        }

        img {
            width: 30px;
            height: 30px;
        }
    }

    @media (max-width: 480px) {
        h1 {
            font-size: 20px;
        }

        img {
            width: 25px;
            height: 25px;
        }
    }
`;


const HeaderSection = styled.div`
    font-family: Source Sans Pro,sans-serif; 
    font-size: 14px !important;
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    &:empty::before {
        content: 'N/A';
    }
`

export const STEPS = {
    REPAIR_INFORMATION: 'Repair Information',
    ADDRESS_INFORMATION: 'Address Information',
    CONTACT_INFROMATION: 'Contact Information',
    EVALUATION_FEE: 'Evaluation Fee'
}

const FIELD_DEFINITIONS = {
    EQUIPMENT_SERIAL_NUMBER: '44444444-4444-4444-4444-444444444444',
    REPAIR_REASON: '77777777-7777-7777-7777-777777777777',
    NOTE_KEY: '99999999-9999-9999-9999-999999999999',
}

const RequestQuotedRepair = () => {
    const location = useLocation();
    const history = useHistory();

    const oems = useOems();
    const dispatch = useDispatch();
    const elementRef = useRef(null);
    const user = useSelector(state => state.user);


    const { quantity = 0,
        product = null,
        option: selectedOption = null,
        isQuote = false,
        hasLoanerAdded = false,
        shouldAutoSource = false } = location?.state || {};
      
    const [showAddToCartConfirmation, setShowAddToCartConfirmation] = useState(false);
    const [showFacilityDialog, setShowFacilityDialog] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [depotProduct, setDepotProduct] = useState(null);
    const [isSaving, setISaving] = useState(false);
    const [steps, setSteps] = useState([]);

    const facilityId = user?.facility?.facilityId;

    useEffect(() => {
        if (!facilityId)
            setShowFacilityDialog(true);
    }, [facilityId])

    useEffect(() => {
        if (!quantity) {
            history.push('/');
            return;
        }

        const companyAndVendorFields = getCompanyAndVendorFields(false, false, selectedOption, facilityId);

        const showEvaluation = selectedOption && selectedOption.evaluationFee > 0;
        const isFromPDP = product && selectedOption;

        const requiredFields = companyAndVendorFields?.filter(field =>
            field.isRequired && field.fieldUid !== FIELD_DEFINITIONS.EQUIPMENT_SERIAL_NUMBER && field.fieldUid !== FIELD_DEFINITIONS.REPAIR_REASON
        ) || [];

        const itemFields = companyAndVendorFields?.filter(field =>
            field.fieldUid === '44444444-4444-4444-4444-444444444444' || field.fieldUid === FIELD_DEFINITIONS.REPAIR_REASON
        ) || [];

        if (!itemFields.some(field => field.fieldUid === FIELD_DEFINITIONS.REPAIR_REASON)) {
            itemFields.push({
                fieldUid: FIELD_DEFINITIONS.REPAIR_REASON,
                isDefault: true,
                isRequired: true,
                lineItemId: 0,
                orderId: 0,
                prompt: 'Repair Reason',
                value: '',
            });
        }

        const repairSteps = Array.from({ length: quantity }, (_, index) => ({
            title: STEPS.REPAIR_INFORMATION,
            data: { oems, index, isFromPDP, 
                addLoaner: hasLoanerAdded, 
                loanerAvailable: selectedOption ? selectedOption.loanerAvailable : true, 
                fields: [...requiredFields, ...itemFields],
                disableOem: !!product?.manufacturerId, 
                oemId: product?.manufacturerId},
            renderContent: (data) => (
                <RepairInformationStep
                    onNext={onSaveStepData}
                    key={`repair_information_${index}`}
                    {...data}
                />
            ),
        }));

        const otherSteps = [
            {
                title: STEPS.ADDRESS_INFORMATION,
                data: { index: repairSteps.length },
                renderContent: (data) => <AddressInformationStep
                    onNext={onSaveStepData}
                    key="address_information"
                    {...data}
                />,
            },
            {
                title: STEPS.CONTACT_INFROMATION,
                data: { index: repairSteps.length + 1, nextIsPreview: !showEvaluation },
                renderContent: (data) => <ContactInformationStep
                    onNext={!showEvaluation ? onPreview : onSaveStepData}
                    key="contact_information"
                    {...data}
                />,
            },
        ];

        if(showEvaluation) {
            otherSteps.push({
                title: STEPS.EVALUATION_FEE,
                data: { index: repairSteps.length + 2, evaluationFee: selectedOption?.evaluationFee },
                renderContent: (data) => <EvaluationFeeStep
                    onPreview={onPreview}
                    key="contact_information"
                    {...data}
                />,
            })
        }

        setSteps([...repairSteps, ...otherSteps]);

    }, [product, selectedOption, oems, quantity, facilityId]);

    const onSaveStepData = (stepIndex, data) => {
        setSteps((prevSteps) =>
            prevSteps.map((step, index) =>
                index === stepIndex
                    ? {
                        ...step,
                        data: { ...step.data, ...data },
                        completed: true,
                        header: <HeaderSection>Done</HeaderSection>,
                    }
                    : step
            )
        );
        scrollToElement();
    };

    const onPreview = (stepIndex, data) => {
        onSaveStepData(stepIndex, data);
        setShowPreview(true);
    }

    const scrollToElement = () => {
        if (elementRef.current) {
            elementRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const onHandleGoBack = () => {
        setShowPreview(false);
        scrollToElement();
    }

    const onSelectFacility = (facility) => {
        dispatch(saveUserReduxState({ facility }));
        setShowFacilityDialog(false);
    }

    const onSubmitRequest = async () => {
        setISaving(true);
    
        const payload = getCartItems();
        let requests = [];
        
        if (selectedOption && product) {
            for (let i = 0; i < payload.length; i++) {        
                dataLayer.push({
                    event: 'brAddToCart',
                    brProductId: product.id,
                    brProductSku: product.id,
                }, {
                    event: 'addToCart',
                    ecommerce: {
                        add: {
                            products: [{
                                name: product.partNumber,
                                id: product.id,
                                price: selectedOption?.price,
                                brand: product?.manufacturer,
                                category: product?.modalityId,
                                variant: `${getPartCondition(selectedOption?.lineItemCondition)} - ${getPurchaseChoice(selectedOption?.purchaseChoice)}`,
                                quantity: 1,
                                AddLoaner: hasLoanerAdded,
                            }],
                            id_ins: payload[i]?.Id_ins,
                        },
                    },
                });
        
                requests.push(axios.post('/ShoppingService/api/v1/cart/add', payload[i]));
            }
        } else {
            requests.push(axios.post('/ShoppingService/api/v1/quotes/request', payload));
        }
    
        try {
            const responses = await Promise.all(requests);
            setShowAddToCartConfirmation(true);
            dispatch(cartActions.loadUserCart());
        } catch (errors) {
            console.log(errors)
        } finally {
            setISaving(false);
        }
    }

    const convertFieldsToDictionary = (fields) => {
        const fieldDict = {};
        fields?.forEach(field => {
            fieldDict[field.fieldUid] = field.value;
        });
        return fieldDict;
    };
    
    const getCartItems = () => {
        const productData = product || {};
        const selectedOptionData = selectedOption || {};
    
        const { images, researchRedisId, id: productId, manufacturerId, displayPartNumber, partNumber } = productData;
        const { facilityId, groupId, phoneNumber } = user.facility;
        const { vendorId, lineItemCondition, purchaseChoice, fakeRefurb, description, lineItemWarranty, isProStockItem, formularySetting, oemItemNumber, variantId } = selectedOptionData;
        const partImage = images && images[0]?.image177;

        const storageData = sessionStorage.fields ? JSON.parse(sessionStorage.fields) : [];
        let note = storageData.find(f => f.fieldUid === FIELD_DEFINITIONS.NOTE_KEY);
    
        const contactInfoStep = steps.find(x => x.title === STEPS.CONTACT_INFROMATION) || { data: {} };
        const { data: { contactId } = {} } = contactInfoStep;
    
        const addressInfoStep = steps.find(x => x.title === STEPS.ADDRESS_INFORMATION) || { data: {} };
        const { data: { shippingAddressId, billingAddressId } = {} } = addressInfoStep;

        const evaluationFeeStep = steps.find(x => x.title === STEPS.EVALUATION_FEE) || { data: {} };
        const { data: { acceptedRepairEvalFee } = {} } = evaluationFeeStep;

    
        if (!selectedOption && !product) {
            const [{data: {model, modelId, addLoaner, oemId, fields, serviceNeeded, imagePreviewUrl}}] = steps;
            
            const quoteRequest = {
                PartNumber: 'Depot Repair',
                CustomerOrderKey: sessionStorage.wo_id ? sessionStorage.wo_id : '',
                Description: serviceNeeded,
                OemId: oemId,
                FacilityId: user.facility.facilityId,
                Quantity: 1,
                RequestorId: contactId,
                Facility: user.facility.facilityName,
                UploadedImage: imagePreviewUrl || '',
                EmailAddress: contactInfoStep.data.email,
                GroupId: user.facility.groupId,
                Fields: convertFieldsToDictionary(fields),
                PhoneNumber: user.facility.phoneNumber,
                QuoteTypeId: 4,
                LocationDetail: addressInfoStep.data.locationDetail,
                ModelId: modelId,
                Model: model,
                AddLoaner: addLoaner,
                RequestType: "Unselected",
                ShippingSpeedId: '',
                serviceNeeded,
                shippingAddressId,
                billingAddressId,
            };

            setDepotProduct({description: serviceNeeded, displayPartNumber: 'Depot Repair', partImages: {}, quantity: 1, productTypeId: 4});
            return quoteRequest;
        }
    
        const cartItems = steps
            .filter(step => step.title === STEPS.REPAIR_INFORMATION)
            .map(step => {
                const { data: { fields = [], serviceNeeded, modelId, oemId, addLoaner } = {} } = step;
                let customFields = [...fields];
    
                if (customFields.length === 0 && note !== null) {
                    customFields.push(note);
                }
    
                const fieldValues = customFields;
                const id_ins = getCookie('id_ins');
    
                let cartItem = {
                    UserId: user.info.contactId,
                    CompanyId: facilityId,
                    GroupId: groupId,
                    RequesterId: user.info.contactId,
                    PhoneNumber: phoneNumber,
                    VendorId: vendorId || 0,
                    ConditionId: lineItemCondition || 0,
                    purchaseChoiceId: purchaseChoice || 0,
                    ResearchRedisId: researchRedisId,
                    Quantity: 1,
                    ProductId: productId,
                    FakeRefurb: fakeRefurb,
                    OemId: manufacturerId,
                    partNumber: displayPartNumber,
                    PartNumberStripped: partNumber,
                    description: description,
                    ImagePath: partImage,
                    WarrantyId: lineItemWarranty || 0,
                    Fields: fieldValues.filter(x => !x.formularyField),
                    IsQuote: isQuote,
                    IsProStockItem: isProStockItem || false,
                    UrgencyId: false,
                    CustomerOrderKey: sessionStorage.wo_id ? sessionStorage.wo_id : '',
                    FormularyFields: [],
                    Id_ins: id_ins,
                    ContractProId: formularySetting?.contractId || '',
                    OemItemNumber: oemItemNumber,
                    AddLoaner: addLoaner,
                    ContractValidated: formularySetting ? !formularySetting.hasUnvalidatedContract : false,
                    VariantId: variantId,
                    LocationDetail: addressInfoStep.data.locationDetail,
                    serviceNeeded,
                    modelId,
                    oemId,
                    shippingAddressId,
                    billingAddressId,
                    acceptedRepairEvalFee,
                    shouldAutoSource,
                };
    
                if (cartItem.ImagePath === 'https://assets.partsfinder.com/image/') cartItem.ImagePath = '';
    
                return cartItem;
            });
    
        return cartItems;
    };
    
    const handleAddToCartCancel = () => {
        setShowAddToCartConfirmation(false);
        history.push('/')
    }
    
    return (
        <div className='row'>
            <MainContainer className='col-xs-12'>
                {<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Header ref={elementRef} className='col-xs-12 col-md-6'>
                        <img src="/images/preview-full-icn_repair.png" />
                        <h1>{showPreview ? `Let's confirm all the information is correct.` : `${product?.title ?? 'Request Quoted Repair'}`}</h1>
                    </Header>
                    {!showPreview && steps && steps.length > 0 && <Stepper steps={steps} />}
                    {showPreview && <PreviewBeforeRequestStep
                        onGoBack={onHandleGoBack}
                        onSubmitRequest={onSubmitRequest}
                        saving={isSaving}
                        steps={steps} />}
                </div>}
            </MainContainer>
            {showFacilityDialog && <FacilityDialog
                onSubmit={onSelectFacility}
                disableClose={true} />}
            {showAddToCartConfirmation && <AddToCartConfirmation 
            show={true} 
            product={product ?? depotProduct} 
            isQuote={isQuote} 
            quantity={quantity} 
            hasLoanerAdded={steps.some(x=> x.data?.addLoaner)} 
            option={selectedOption} 
            confirmButtonText="VIEW REPAIR QUOTES" 
            onConfirm={() => history.push('/repairs?tab=initiated')} 
            onCancel={handleAddToCartCancel} 
            />}

        </div>
    )
}

export default RequestQuotedRepair;
