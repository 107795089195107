import React, { useContext } from "react";
import { Button } from "@partssourceinc/react-ui-core/components";
import styled from "styled-components";
import { STEPS } from "../../RequestQuotedRepair";
import { getFormattedPhoneNumber } from 'utility';
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { ProductImage } from '@partssourceinc/react-ui-core';
import { formatMoney } from 'utility'


const Divider = styled.div`
  border-bottom: 1px solid #EDEEF1;
  margin: 24px 0 !important;
`;

const Title = styled.div`
  color: #696969;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.352px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: left;
`;

const Label = styled.strong`
  font-size: 11px;
`;

const Value = styled.span`
  font-size: 18px;
`;

const LabelValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const Box = styled.div`
`;

const ButtonContainer = styled.div`
  float: right;
  margin-bottom: 30px;
`;

export const Link = styled.a`
margin-right: 30px !important;
    &&& {
        text-transform: uppercase;
        cursor: pointer;
        color: #949494;
        font-weight: bold;
        margin-right: 1em;

        &:hover {
            text-decoration: underline;
            outline: none;
        }

        &:focus {
            text-decoration: underline;
            outline: none;
        }

        &:active:focus {
            text-decoration: underline;
            outline: none;
        }
    }
`;

const MainContainer = styled.div`
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const LabelValue = ({ label, value }) => (
    <LabelValueContainer>
        <Label>{label}</Label>
        <Value>{value}</Value>
    </LabelValueContainer>
);

const RepairInformationPreview = ({ data }) => (
    <Container className="col-xs-12">
        <Title>Repair Information</Title>
        <LabelValue label="OEM" value={data?.oem} />
        <LabelValue label="Model #" value={data?.modelNumber} />
        <LabelValue label="Asset Name/Model" value={data?.serviceNeeded} />
        {data.fields?.map(field => <LabelValue label={field.prompt} value={field.value} />)}
        <LabelValue label="Loaner Requested" value={data?.addLoaner ? 'Yes' : 'No'} />

    </Container>
);

const AddressInformationPreview = ({ data }) => {
    const facility = useSelector(state => state.user.facility.facilityName);

    return (<Container className="col-xs-12">
        <Title>Shipping Information</Title>
        <LabelValue label="Selected Facility" value={facility} />
        <LabelValue label="Shipping Address" value={data.shippingAddress} />
        <LabelValue label="Billing Address" value={data.billingAddress} />
    </Container>)
};

const ContactInformationPreview = ({ data }) => (
    <Container className="col-xs-12">
        <Title>Contact Information</Title>
        <LabelValue label="Full Name" value={data.fullName} />
        {data?.email ? <LabelValue label="Email" value={data?.email} /> : null }
        {data?.phoneNumber ? <LabelValue label="Phone Number" 
            value={getFormattedPhoneNumber(data?.phoneNumber)} /> : null }
    </Container>
);

const EvaluationFeePreview = ({ data }) => (
    <Container className="col-xs-12">
        <Title>Evaluation Fee</Title>
        <Value>An Evaluation Fee of <strong>{formatMoney(data?.evaluationFee)}</strong> will be charged if the device is returned unrepaired</Value>
    </Container>
);

const renderStep = (step) => {
    switch (step.title) {
        case STEPS.REPAIR_INFORMATION:
            return <RepairInformationPreview data={step.data} />;
        case STEPS.ADDRESS_INFORMATION:
            return <AddressInformationPreview data={step.data} />;
        case STEPS.CONTACT_INFROMATION:
            return <ContactInformationPreview data={step.data} />;
        case STEPS.EVALUATION_FEE:
            return <EvaluationFeePreview data={step.data} />;
        default:
            return null;
    }
};


export function PreviewBeforeRequestStep({ onSubmitRequest, saving, onGoBack, steps }) {
    const location = useLocation();

    const { option } = location?.state || {};
    const imageUrl = option?.images[0]?.image300;
    return (
        <div className="col-md-6 col-xs-12">
            <MainContainer className="row">
                <Box className="col-xs-12 col-md-3">
                    <ProductImage url={imageUrl} style={{width: '100%'}} />
                </Box>
                <div className="col-xs-12 col-md-9">
                    <Column>
                        {steps.map((step, index) => (
                            <React.Fragment key={index}>
                                {renderStep(step)}
                                {(index < steps.length - 1) && <Divider />}
                            </React.Fragment>
                        ))}
                    </Column>
                </div>
            </MainContainer>
            <Divider />
            <ButtonContainer>
                <Link onClick={onGoBack}>Go Back</Link>
                <Button loading={saving} onClick={onSubmitRequest}>Request Repair Quote</Button>
            </ButtonContainer>
        </div>
    );
}