import React, { useState } from "react";
import { Button, TextField, TextFieldSelect } from "@partssourceinc/react-ui-core/components";
import styled from "styled-components";
import { useStepper } from "../../../components/Stepper/hooks/useStepper";
import {useFacilityAddresses} from "../../../hooks/useFacilityAddresses";

const Divider = styled.div`
    border-bottom: 1px solid gray;
    margin-top: 30px;
`;

export function AddressInformationStep(props) {
    const { moveNext } = useStepper();
    const { onNext, data } = props;
    const addressData = useFacilityAddresses();

    const [formState, setFormState] = useState(data);
    const [errors, setErrors] = useState({});

    const selectedShippingAddress = addressData?.shippingAddresses?.find(x => x.addressId === parseInt(formState.shippingAddressId));
    const selectedBillingAddress = addressData?.billingAddresses?.find(x => x.addressId === parseInt(formState.billingAddressId));

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormState({ ...formState, [name]: value });
    };

    const onDropdownChange = (name, value) =>
        setFormState({ ...formState, [name]: value });

    const validate = () => {
        let tempErrors = {};
        if (!formState.shippingAddressId) tempErrors.shippingAddressId = "Shipping Address is required";
        if (!formState.billingAddressId) tempErrors.billingAddressId = "Billing Address is required";
        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (validate()) {
            const {addressDisplay: billingAddress} = selectedBillingAddress;
            const {addressDisplay: shippingAddress} = selectedShippingAddress;
            
            onNext(data.index, { ...formState, shippingAddress, billingAddress });
            moveNext();
        }
    };

    return (
        <div className="col-xs-12">
            <div className="col-xs-12" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div className="col-xs-12" style={{ textAlign: 'left' }}>

                    <TextFieldSelect
                        name="shippingAddressId"
                        keyField="addressId"
                        valueField="description"
                        onSelect={data => onDropdownChange('shippingAddressId', data.addressId)}
                        listItems={addressData?.shippingAddresses || []}
                        label="Shipping Address"
                        placeHolder="Shipping Address"
                        selectedItem={selectedShippingAddress ?? {}}
                        disableClear={false}
                        showErrorMessage={!!errors.shippingAddressId}
                        tabIndex={1}
                    />

                    <TextFieldSelect
                        name="billingAddressId"
                        keyField="addressId"
                        valueField="description"
                        onSelect={data => onDropdownChange('billingAddressId', data.addressId)}
                        listItems={addressData?.billingAddresses || []}
                        label="Billing Address"
                        placeHolder="Billing Address"
                        selectedItem={selectedBillingAddress ?? {}}
                        disableClear={false}
                        showErrorMessage={!!errors.billingAddressId}
                        tabIndex={2}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12">
                    <Divider />
                    <Button onClick={handleSubmit} style={{ float: 'right', marginTop: 30 }}>Next</Button>
                </div>
            </div>
        </div>
    );
}
