import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators as companyActions } from '../store/Companies';

export const useOems = () => {
    const dispatch = useDispatch();
    const oems = useSelector(state => state.companies.oems);

    useEffect(() => {
        if (!oems.length > 0) {
            dispatch(companyActions.getOems());
        }
    }, [oems]);

    return oems;
};
