import styled from 'styled-components';
import React, {useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useSwipeable} from 'react-swipeable';
import {StepperContext, StepperProvider} from './contexts/StepperContext';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft, faChevronRight, faPen, faPencilAlt} from '@fortawesome/free-solid-svg-icons';

const isMobileView = window && window.innerWidth <= 400;
const windowSize = window.innerWidth;

const MainContainer = styled.div`
    text-align: center;
    width: 100%;
    margin-bottom: 150px;

    .loading-data {
        top: 0;
        margin-top: 0;
    }
`;

const SliderContainer = styled.div`
    max-width: 100%;
    overflow: hidden;
    position: relative;
    overflow-wrap: break-word;
    margin-bottom: 30px;
`;

const SliderWrapper = styled.div`
    max-width: 100%;
    display: flex;
    transition-duration: .4s;
    margin-left: ${props => {
    const gap = 50;
    const step = props.step;
    const stepOffset = isMobileView ? windowSize : 330;
    const centerOffset = ((stepOffset * step) + gap * (step - 1)) - (stepOffset / 2);
    const marginLeft = isMobileView ? `-${centerOffset}px` : `calc(50% - ${centerOffset}px)`;
    return step !== 0 ? marginLeft : '0';
}};
`;

const Slider = styled.div`
    display: flex;
    flex-wrap: nowrap;
`;

const StepCard = styled.div`
    width: ${isMobileView ? 'calc(100vw - 60px)' : '330px'};
    border: 2px solid rgb(119, 119, 119);
    min-height: 98px;
    text-align: left !important;
    opacity: .5;
    border-radius: 3px;
    padding: 24px;
    position: relative;
    cursor: ${props => props.isVisited ? 'pointer' : 'undefined'};

    &.visited {
        opacity: 1;
    }

    &.completed {
        border: 2px solid rgb(22, 170, 119);
    }

    &.active {
        background: rgba(255, 149, 5, 0.1);
        border: 2px solid rgb(255, 149, 5);
    }
`;
const CardRow = styled.div`
    display: flex;
    align-items: center;
    span {
        font-size: 16px;
        font-weight: 600;
        margin-left: 12px;
    }
`;

const StepStatus = styled.div`
    font-size: 14px;
`;

const CardBottom = styled.div`
    margin-left: 36px;
`;

const Divider = styled.div`
    display: flex;
    position: relative;
    width: 50px;

    span {
        position: absolute;
        height: 2px;
        background-color: rgb(220, 220, 220);
        width: 100%;
        top: 50%;
    }
`;

const CheckedIcon = styled.img`
    background-size: 24px;
    vertical-align: middle;
    position:relative;
    height: 24px;
    width: 24px;
`;

const ChevronArrow = styled(FontAwesomeIcon)`
    text-decoration: none;
    font-size: 15px;
    color: rgb(108, 108, 108);
    line-height: 45px;

`

const LeftArrow = styled.div`
    background: rgb(255, 255, 255);
    box-shadow: 0px 0px 7px 0px rgba(167, 167, 167, 0.5);
    height: 48px;
    width: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 45px;

    border-radius: 50%;
    cursor: pointer;    
    position: absolute;
    top: calc(50% - 24px);
    left: 30px;

    @media(max-width: 605px) {
        display: none;
    }
`;

const RightArrow = styled.div`
    background: #ffffff;
    box-shadow: 0px 0px 7px 0px rgb(167 167 167 / 50%);
    height: 48px;
    width: 48px;
    border-radius: 50%;
    cursor: pointer;
    position: absolute !important;
    top: calc(50% - 24px);
    right: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 45px;
`;

const EditCardIconWrapper = styled.span`
    border: 2px solid black !important;
    color: black;
    width: 20px;
    height: 15px;
    border-radius: 100%;
`;

const RightEditIcon = styled(FontAwesomeIcon)`
    cursor: pointer;
    font-size: 12px;
    padding: 4px;
    border-radius: 100px;
    color: gray; 
    fontSize: 12px; 
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 150px;
`;

const Body = styled.div`
    width: 490px;
    display: flex;
    justify-content: center;
    text-align: center;
    .field {
        text-align: left;
    }
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
`;

const CardTop = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const PencilIcon = styled(FontAwesomeIcon)`
    border: 2px solid black;
    border-radius: 50%;
    height: 21px;
    width: 21px !important;
    padding-left: 3px;
    padding-right: 3px;
`;


const Stepper = (props) => {
    return (<StepperProvider>
        <StepperImplmentation {...props} />
    </StepperProvider>)
}

const CardContainerClasses = (completed, active, visited) => [
    completed && 'completed',
    visited && 'visited',
    active && 'active',
].filter(Boolean).join(' ');

const StepperImplmentation = (props) => {
    const {currentStep, moveNext, goPrevious, visitedSteps, markVisited, jumpToStep} = useContext(StepperContext);
    const {steps, progressText, hideProgressText, hideEditIcon, leftIcon, rightIcon, completeOnNext, stepIndex} = props;

    const leftArrowClickable = currentStep > 1;
    const rightArrowClickable = (steps[currentStep - 1] || {}).completed;

    useEffect(() => {
        if (!visitedSteps.some(x => x === currentStep)) {
            markVisited(currentStep);
        }
    }, [currentStep, visitedSteps, markVisited])

    useEffect(() => {
        if(stepIndex) {
            jumpToStep(stepIndex);
        }
    }, [stepIndex])

    const handlers = useSwipeable({
        onSwipedRight: () => rightArrowClickable ? moveNext() : () => undefined,
        onSwipedLeft: () => leftArrowClickable ? goPrevious() : () => undefined,
        preventScrollOnSwipe: true,
        swipeDuration: 500,
        trackMouse: true,
    });

    function RenderContentWithProps () {

        if (currentStep > steps.length) {
            jumpToStep(steps.length);
            return <></>;
        }

        const Content = steps[currentStep - 1]?.renderContent;
        const data = steps[currentStep - 1]?.data;

        if (Content !== null) {
            Content.displayName = 'Content_' + currentStep;
            Content.key = 'Content_' + currentStep;

        }

        return (<Content data={data} />)
    }

    return (
        <MainContainer>
            <SliderContainer>
                <SliderWrapper step={currentStep}>
                    <Slider {...handlers}>
                        {steps && steps.map((item, index, array) => {

                            const stepNumber = index + 1
                            const showDevider = (index < steps.length - 1);
                            const isVisited = (item.visited !== null && item.visited !== undefined) ? 
                                item.visited : visitedSteps.some(x => x === stepNumber);
                            const isCompleted = item.completed || 
                                (isVisited && stepNumber < currentStep && completeOnNext);
                            const isLastStep = index === array.length - 1;
                            const isDone = isLastStep && isCompleted;
                            const isActive = isDone ? false : stepNumber === currentStep;

                            return (<>
                                <StepCard isVisited={!isActive && isCompleted} 
                                onClick={()=> !isActive && isCompleted && jumpToStep(stepNumber)} 
                                className={CardContainerClasses(isCompleted, isActive, isVisited)} 
                                    key={item.title + '_' + index}>
                                    <CardBody>
                                        <CardTop>
                                            <CardRow>
                                                {(!isCompleted || isActive) ?
                                                    <PencilIcon icon={faPencilAlt} size='lg' /> :
                                                    <CheckedIcon src={'/images/icn_checkmark.png'} />}
                                                <span>{item?.title}</span>
                                            </CardRow>
                                            {(!hideEditIcon && !isActive && isVisited) &&
                                                <RightEditIcon icon={rightIcon}
                                                    onClick={() => isVisited && jumpToStep(index + 1)} />}
                                        </CardTop>
                                        <CardBottom>
                                            {(!hideProgressText && isActive) ?
                                                <StepStatus>{progressText}</StepStatus> : null}
                                            {(!isActive || isDone) ? item?.header : null}
                                        </CardBottom>
                                    </CardBody>
                                </StepCard>
                                {showDevider && <Divider key={'divide_' + index}><span /></Divider>}
                            </>)
                        })}
                    </Slider>
                </SliderWrapper>
                {leftArrowClickable && 
                    <LeftArrow onClick={goPrevious}><ChevronArrow icon={faChevronLeft} /></LeftArrow>}
                {rightArrowClickable && 
                    <RightArrow onClick={moveNext}><ChevronArrow icon={faChevronRight} /></RightArrow>}
            </SliderContainer>
            <Container>
                <Body>
                    {RenderContentWithProps()}
                </Body>
            </Container>
        </MainContainer>
    );
};

Stepper.propTypes = {
    progressText: PropTypes.string,
    hideProgressText: PropTypes.bool,
    completeOnNext: PropTypes.bool,
    hideEditIcon: PropTypes.bool,
    successIcon: PropTypes.node,
    leftIcon: PropTypes.node,
    rightIcon: PropTypes.node,
    steps: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            header: PropTypes.string.isRequired,
            renderContent: PropTypes.func.isRequired,
        }),
    ).isRequired,
};

Stepper.defaultProps = {
    completeOnNext: false,
    hideProgressText: false,
    hideEditIcon: false,
    progressText: 'In Progress',
};

export default Stepper;
