import {StepperContext} from '../contexts/StepperContext';
import {useContext} from 'react';

export const useStepper = () => {
    const context = useContext(StepperContext);
    if (!context) {
        throw new Error('useStepper must be used within a StepperProvider');
    }

    const {currentStep, moveNext, goPrevious} = context;

    return {currentStep, moveNext, goPrevious};
};