import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux';
import {Button} from '@partssourceinc/react-ui-core';
import {tccServices, tccCustCareServices} from 'data/TeamContactCenterData';
import axios from 'axios';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';
import PageMetaData from 'components/PageMetaData';
import {getFormattedPhoneNumber} from 'utility';

const TccTopSection = styled.div`
    background-image: url('/images/TeamContactCenter/partssource-rapid-response-team.jpg');
    height: 226px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    .tcctitle {
        color: rgb(255, 255, 255);
        height: 42px;
        font-size: 34px;
        font-weight: bold;
        text-align: center;
        padding-top: 55px;
        letter-spacing: -0.1px;
        line-height: 1.1;
    }
    .subheader{        
        height: 50px;
        color: rgb(255, 255, 255);
        font-size: 20px;
        font-weight: normal;
        text-align: center;
        letter-spacing: -0.06px;
        line-height: 25px;
        padding-top: 50px;
    }
`;

const CustomerCareSection = styled.div`
    padding-top: 60px;
    background: rgb(255, 255, 255);
    border-radius: 0;
    text-align: center;
    letter-spacing: 0;

        div.cc-title {
            color: rgb(41, 171, 226);
            font-size: 24px;
            font-weight: 600;
            padding-bottom: 10px;
            overflow-wrap: break-word;
        }
        div.subhead {
            color: rgb(0, 0, 0);
            font-size: 30px;
            font-weight: 300;
            line-height: 1.1;
            overflow-wrap: break-word;
        }
        div.info {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            padding-top: 16px;
            color: rgb(0, 93, 166);
            font-weight: bold;
            font-size: 16px;

            a {
                color: rgb(0, 93, 166);
            }
            span.phone {
                flex: 0 0 auto;
                text-align: right;
                margin-right: 30px;
                padding-bottom: 6px;
            }
            span.email {
                flex: 0 0 auto;
                margin-left: 5px;
            }
        }
        div.services {
            color: rgb(0, 93, 166);
            font-size: 16px;
            font-weight: 600;
            text-align: center;
            letter-spacing: 0px;

            div.header{
                color: rgb(0, 0, 0);
                font-weight: normal;
                padding-top: 0 !important;
                margin-top: 40px;
            }
            
            div.services-container {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                margin-top: 30px;

                div.services-card {
                    border: 0;
                    flex: 0 0 20%;
                    text-decoration: underline;
                    text-align: center;
                    letter-spacing: 0;
                }
                @media (max-width: 768px) {
                    .services-card {
                        flex: 0 0 33.33% !important;
                    }
                }
                @media (max-width: 480px) {
                    .services-card {
                        flex: 0 0 50% !important;
                    }
                }
            }
        }
`;

const AdditionalInfoSection = styled.div`
    margin-top: 40px;
    background: linear-gradient(-243.43494882292202deg, rgb(0, 170, 236) 0%, rgb(0, 91, 188) 100%);
    border-radius: 0px;
    text-align: center;
    letter-spacing: 0px;
    div.ar-title {
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 80px;
        color: rgb(255, 255, 255);
        font-size: 24px;
        font-weight: 600;
        line-height: 1.1;
        overflow-wrap: break-word;
    }
    div.subhead{
        padding-top: 16px;
        padding-left: 40px;
        padding-right: 40px;
        padding-bottom: 40px;
        color: rgb(255, 255, 255);
        font-size: 30px;
        font-weight: 300;
        line-height: 1.1;
        overflow-wrap: break-word;
    }
    div.cta{
        padding-bottom: 80px;
    }
`;

const AccountManagersContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    padding-top: 60px;
    background: rgb(255, 255, 255);
    border-radius: 0px;
    text-align: center;
    letter-spacing: 0px;
    
    div.am-card {
        overflow: auto;
        flex-basis: calc(50% - 20px);
        flex-grow: 1;
        box-sizing: border-box;
        padding-top: 80px;
        padding-bottom: 80px;
        padding-left: 60px;
        padding-right: 60px;
        background: rgb(241, 241, 241);
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
        border-radius: 3px;
        text-align: center;

        div.am-title {
            color: rgb(0, 0, 0);
            font-size: 30px;
            font-weight: 300;
            padding-bottom: 10px;
            line-height: 1.1;
            overflow-wrap: break-word;
        }
        div.subheader {
            color: rgb(0, 0, 0);
            font-size: 16px;
            font-weight: normal;
            line-height: 24px;
            padding-bottom: 10px;
            overflow-wrap: break-word;
        }
        img {
            max-width: 150px;
        }
        div.am-contact {
            font-size: 20px;
            padding-top: 10px;
            padding-bottom: 4px;
        }
        div.am-services-container {
            margin-top: 4px;
            font-size: 16px;
            line-height: 1.3;
        }
        div.info {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            padding-top: 30px;
            color: rgb(0, 93, 166);
            font-weight: bold;
            font-size: 16px;

            a {
                color: rgb(0, 93, 166);
            }
            span.phone {
                flex: 0 0 auto;
                text-align: right;
                margin-right: 30px;
                padding-bottom: 6px;
            }
            span.email {
                flex: 0 0 auto;
                margin-left: 5px;
            }
        }
    }
    @media (max-width: 820px) {
        /* Show only 1 card on a row */
        .am-card {
            flex-basis: 100% !important;
            max-width: 100% !important;
        }
    }

    &.more-than-two {
        /* Show 3 cards on a row */
        .am-card {
            flex-basis: calc(33.33% - 20px) !important;
            max-width: calc(33.33% - 20px) !important;
        }

        @media (max-width: 1150px) {
            /* Show only 1 card on a row */
            .am-card {
                flex-basis: 100% !important;
                max-width: 100% !important;
            }
        }    
    }
`;

const TeamContactCenterLanding = () => {
    const history = useHistory();
    const companyId = useSelector(state => state.user.info.rootCompanyId);    
    const [showRRT, setShowRRT] = useState(false);
    const [showAM, setShowAM] = useState(false);    
    const [dataAM, setDataAM] = useState([]);
    const [dataCC, setDataCC] = useState();
    const [dataAR, setDataAR] = useState();
    const [phoneCC, setPhoneCC] = useState();
    const [servicesCC, setServicesCC] = useState([]);

    useEffect(() => {
        if (companyId)
            loadData();
    }, [companyId]);

    const loadData = () => {

        axios.get(`/ShoppingService/api/v1/company/teamcontact?companyId=${companyId}`).then(x => {
            const isEnabled = x.data.teamCenterSettings.displayRapidResponsePage === 'Y';
            if(!isEnabled)
                history.push('/');
            if (x.data.teamCenterSettings) {
                setShowRRT(isEnabled);
                setShowAM(x.data.teamCenterSettings.teamAccountManagersActive === 'Y');
            }
            if (x.data && x.data.teamAccountManagers && x.data.teamAccountManagers.length > 0) {
                x.data.teamAccountManagers.forEach(m => { 
                    if (m.services) {
                        let v = m.services.split(',');
                        v.join('').replace(/\s/gi,'').length === 0 ? m.services = null : v;
                    }
                });
            }
            if (x.data && x.data.customerCare) {
                if (x.data.customerCare.phone)
                    setPhoneCC(getFormattedPhoneNumber(x.data.customerCare.phone));
                if (x.data.customerCare.services) {
                    let c = x.data.customerCare.services.split(',');
                    c.join('').replace(/\s/gi,'').length === 0 ? null : c;
                    setServicesCC(c);
                }
            }

            setDataAM(x.data.teamAccountManagers.filter(x => x.isActive === true).sort((a, b) => a.priority - b.priority));
            setDataCC(x.data.customerCare);
            setDataAR(x.data.additionalResource);   
        });
        
    };
    function clickCta(ctaLink) {
        let pref = '';
        if (!(/^https?:\/\//.test(ctaLink) || /^http?:\/\//.test(ctaLink)))
            pref = 'https://'
        window.open(pref + ctaLink, '_blank');
    }
    const renderServices = (item, i) => {
        const service = tccCustCareServices.find(s => s.id === item);
        return (
            <div className="services-card" key={`srv_${i}`}>
                <div><img src={`${service.img}`} alt={`${service.display}`} /></div>
                <div style={{marginTop: '16px', paddingBottom: '20px'}}>{service.display}</div>
            </div>
        );
    };
    const renderAMServices = (id) => {
        const service = tccServices.find(s => s.id === id);
        return (
            <div key={`am_srv_${id}`}>
                {service.display}
            </div>
        );
    };
    const renderAM = (item, i) => {
        const amServices = item.services && item.services.length > 0 && item.services.split(',');

        return (
            <div className="am-card" key={`am_${i}`}>
                <div className="am-title">{item.title}</div>
                <div className="subheader">{item.subheader}</div>
                <div><img src={`data:image/png;base64,${item.headshotData}`} alt={`${item.name}`} /></div>
                {amServices && <div>
                    <div className="am-contact">Contact <b>{item.name}</b> for:</div>
                    <div className="am-services-container">{amServices.map((id) => renderAMServices(id))}</div>
                </div>}
                <div className="info">
                    <span className="phone">
                        <img src="/images/TeamContactCenter/icn-phone.svg" title={`Call Us`} alt={`Phone Number`} style={{marginRight: '10px'}} />      
                        <a href={'tel:+1' + item.phone}>{getFormattedPhoneNumber(item.phone)}</a>
                    </span>
                    <span className="email">
                        <img src="/images/TeamContactCenter/icn-email.svg" title={`Email Us`} alt={`Email Address`} style={{marginRight: '10px'}} />
                        <a href={`mailto:${item.email}`}>{item.email}</a>
                    </span>
                </div>
            </div>
        );
    };
    
    return (
        <React.Fragment>
            <PageMetaData title="Team Contact Center" />
            <div>
                <TccTopSection>
                    <div className="tcctitle">About Your Team</div>                        
                    <div className="subheader">Access Expert Help: Discover Customized Support Options for Your Pro Service Needs</div>
                </TccTopSection>
                {dataCC && dataCC.isActive && <CustomerCareSection>
                    <div className="cc-title">{dataCC.title}</div>
                    <div className="subhead">{dataCC.subheader}</div>
                    <div className="info">
                        <span className="phone">
                            <img src="/images/TeamContactCenter/icn-phone.svg" title={`Call Us`} alt={`Phone Number`} style={{marginRight: '10px'}}/>                            
                            <a href={'tel:+1' + phoneCC}>{phoneCC}</a>
                        </span>
                        <span className="email">
                            <img src="/images/TeamContactCenter/icn-email.svg" title={`Email Us`} alt={`Email Address`} style={{marginRight: '10px'}}/>
                            <a href={`mailto:${dataCC.email}`}>{dataCC.email}</a>
                        </span>
                    </div>
                    {servicesCC && servicesCC.length > 0 && <div className="services">
                        <div className="header">Additional reasons include, but are not limited to:</div>
                        <div className="services-container">{servicesCC.map((i, index) => renderServices(i, index))}</div>
                    </div>}
                </CustomerCareSection>}
                {showAM && dataAM && dataAM.length > 0 && <AccountManagersContainer className={`${dataAM.length > 2 ? 'more-than-two' : ''}`}>
                    {dataAM.map((i, index) => renderAM(i, index))}
                </AccountManagersContainer>}
                {dataAR && dataAR.isActive && <AdditionalInfoSection>
                    <div className="ar-title">{dataAR.title}</div>
                    <div className="subhead">{dataAR.subheader}</div>
                    <div className="cta">
                        <Button style={{'marginTop': '18px'}} secondary={false} 
                            onClick={() => clickCta(dataAR.ctaLink)}>{dataAR.ctaLabel}</Button>
                    </div>
                </AdditionalInfoSection>}
            </div>
        </React.Fragment>
    );
}

export default TeamContactCenterLanding;
