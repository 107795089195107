import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Checkbox, FileUploader, TextField, TextFieldSelect } from "@partssourceinc/react-ui-core/components";
import styled from "styled-components";
import UploadFiles from "../../../components/UploadFiles";
import { useStepper } from "../../../components/Stepper/hooks/useStepper";
import axios from 'axios';
import { useSelector } from "react-redux";

const Divider = styled.div`
    border-bottom: 1px solid gray;
    margin-top: 30px;
`;

const Explanation = styled.div`
    width: 100%;
    height: 34px;
    color: rgb(119, 119, 119);
    font-size: 12px;    
    font-weight: normal;
    letter-spacing: 0px;
    text-align: left;
    margin-top: 8px;
`;


export function RepairInformationStep({ data, onNext }) {
    const { moveNext } = useStepper();
    const [formState, setFormState] = useState(data);
    const [errors, setErrors] = useState({});
    const [models, setModels] = useState([]);
    const allowLoaner = useSelector(state => state.user.settings.allowLoaner);

    const selectedOem = data.oems?.find(x => x.id === formState.oemId);
    const selectedModel = models?.find(x => x.modelId === formState.modelId);


   useEffect(() => {
    let isMounted = true;

    const getModels = async () => {
      try {
        const response = await axios.get(`CatalogService/api/v1/${formState.oemId}/models`);
        let models = response.data;
        if (!models.some(x => x.modelId === -1)) {
          models = [{ modelId: -1, model: '(MODEL NOT LISTED)' }, ...models];
        }
        if (isMounted) {
          setModels(models);
        }
      } catch (error) {
        if (isMounted) {
          console.error(error);
        }
      }
    };

    if (formState.oemId) {
      getModels();
    }

    return () => {
      isMounted = false;
    };
  }, [formState.oemId]);

    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        setFormState(prevState => ({
            ...prevState,
            fields: prevState.fields.map(field =>
                field.fieldUid === name ? { ...field, value } : field
            )
        }));
    };

    const handleFieldDropdownChange = (e, selectedData) => {
        const { id } = e.target;
        setFormState(prevState => ({
            ...prevState,
            fields: prevState.fields.map(field =>
                field.fieldUid === id ? { ...field, value: selectedData.value } : field
            )
        }));
    };

    const validate = () => {
        let tempErrors = {};
        if (!formState.oemId) tempErrors.oemId = "OEM is required";
        if (!formState.modelId) tempErrors.modelId = "Model Number is required";
        if (!formState.serviceNeeded) tempErrors.serviceNeeded = "Service Needed is required";

        formState?.fields?.forEach(field => {
            if (field.isRequired && !field.value) {
                tempErrors[field.fieldUid] = `${field.prompt} is required`;
            }
            if (field.formatRegex && !new RegExp(`^${field.formatRegex}$`).test(field.value)) {
                tempErrors[field.fieldUid] = `${field.prompt} is invalid`;
            }
        });
        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            onNext(data.index, { ...formState, modelNumber: selectedModel.model, oem: selectedOem.name });
            moveNext();
        }
    };

    const onCheckboxChange = (e) => {
        const { name, checked } = e;
        setFormState({ ...formState, [name]: checked });
    }

    const onDropdownChange = (name, value) =>
        setFormState({ ...formState, [name]: value });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormState({ ...formState, [name]: value });
    };

    const onRemovePhoto = () => {
        setFormState({ ...formState, isFileSelected: false, fileDisplay: '', fileInput: '', imagePreviewUrl: '' });
        setErrors({ ...errors, uploadedImage: '' });  // clear any error messages
    };

    const processFiles = (e) => {
        e.preventDefault();

        const acceptedFiles = ['image/jpeg', 'image/png'];
        const files = e.target.files;
        let cleanup = false;

        if (!files || files.length === 0) {
            setErrors({ ...errors, uploadedImage: 'Your file selected was not uploaded. Please try again.' });
            cleanup = true;
        } else {
            for (let i = 0; i < files.length; i++) {
                if (!acceptedFiles.includes(files[i].type)) {
                    setErrors({ ...errors, uploadedImage: 'Acceptable photo formats are .jpg and .png' });
                    cleanup = true;
                    break;
                }
            }
        }

        if (cleanup) {
            onRemovePhoto();
            return;
        }


        const file = files[0];
        const reader = new FileReader();

        reader.onload = () => {
            setFormState((prevState) => ({
                ...prevState,
                isFileSelected: true,
                fileDisplay: $(e.target).val()?.split('/')?.pop()?.split('\\')?.pop(),
                imagePreviewUrl: reader.result,
            }));
        };

        reader.readAsDataURL(file);
    };

    return (
        <div className="col-xs-12">
            <div className="col-xs-12" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div className="col-xs-12" style={{ textAlign: 'left' }} key='render'>
                    <TextFieldSelect
                        id="oemId"
                        name="oemId"
                        keyField="id"
                        valueField="name"
                        listItems={formState?.oems ?? []}
                        label="OEM"
                        placeHolder="OEM"
                        submitDisabled={formState?.disableOem}
                        selectedItem={selectedOem ?? {}}
                        onSelect={data => onDropdownChange('oemId', data.id)}
                        showErrorMessage={!!errors.oemId}
                        errorMessage={errors.oemId}
                        disableClear
                        required={true}
                        tabIndex={0}
                    />

                    <TextFieldSelect
                        id="modelId"
                        name="modelId"
                        keyField="modelId"
                        valueField="model"
                        alwaysShowKey={'-1'}
                        listItems={models ?? []}
                        label="Model Number"
                        submitDisabled={!formState.oemId}
                        placeHolder="Model Number"
                        selectedItem={selectedModel ?? {}}
                        onSelect={data => onDropdownChange('modelId', data.modelId)}
                        showErrorMessage={!!errors.modelId}
                        errorMessage={errors.modelId}
                        disableClear
                        tabIndex={1}
                    />

                    <TextField
                        id="serviceNeeded"
                        name="serviceNeeded"
                        multiLine
                        rows={4}
                        label="Asset Name/Model"
                        placeholder="Asset Name/Model"
                        text={formState.serviceNeeded}
                        onChange={(e) => handleChange(e)}
                        maxLength={512}
                        showErrorMessage={!!errors.serviceNeeded}
                        errorMessage={errors.serviceNeeded}
                        tabIndex={2}
                    />

                    {formState?.fields.map((field, index) =>
                        field.fieldType === 'dropdown' ? (
                            <div key={field.fieldUid}                            >
                                <Dropdown
                                key={field.fieldUid}
                                    id={field.fieldUid}
                                    name={field.fieldUid}
                                    label={field.prompt}
                                    valueField="value"
                                    textField="label"
                                    tabIndex={3 + index}
                                    options={field.options}
                                    selectedValue={field.value}
                                    onChange={handleFieldDropdownChange}
                                    showErrorMessage={!!errors[field.fieldUid]}
                                    errorMessage={errors[field.fieldUid]}
                                />
                            </div>
                        ) : (
                            <div key={field.fieldUid}
                            >
                           
                                <TextField
                                key={field.fieldUid}
                                    id={field.fieldUid}
                                    name={field.fieldUid}
                                    label={field.prompt}
                                    text={field.value}
                                    tabIndex={3 + index}
                                    onChange={e => handleFieldChange(e)}
                                    required={field.isRequired}
                                    errorMessage={errors[field.fieldUid]}
                                    showErrorMessage={!!errors[field.fieldUid]}
                                    pattern={field.formatRegex}
                                    placeholder={`Enter ${field.prompt}`}
                                    multiLine={field.fieldType === 'textarea'}
                                    rows={field.fieldType === 'textarea' ? 4 : 1}
                                    maxLength={field.maxLength || 512}
                                />
                            </div>
                        )
                    )}

                    <Explanation>
                        Examples: Error codes, equipment location(s), quantity of items that need service, additional model numbers and a list of preferred service vendors.
                    </Explanation>

                    {allowLoaner && formState.loanerAvailable && <Checkbox
                        id="addLoaner"
                        name="addLoaner"
                        label="Add Loaner"
                        className="checkbox"
                        tabIndex={3 + formState.fields.length}
                        checked={formState.addLoaner}
                        onChange={onCheckboxChange}
                    />}

                    {!formState?.isFromPDP && <div style={{ marginTop: 30, width: '100%', color: errors.uploadedImage ? 'red' : 'inherit' }}
                        className="request-quote_optional">

                        <FileUploader
                            label="Upload Photo"
                            acceptFiles="image/*"
                            isFileSelected={formState?.isFileSelected}
                            imagePreviewUrl={formState?.imagePreviewUrl}
                            fileDisplay={formState?.fileDisplay}
                            onChange={processFiles}
                            handleRemove={onRemovePhoto}
                        />
                    </div>}
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12">
                    <Divider />
                    <Button onClick={handleSubmit} style={{ float: 'right', marginTop: 30 }}>Next</Button>
                </div>
            </div>

        </div>
    );
}

RepairInformationStep.propTypes = {
    data: PropTypes.shape({
        index: PropTypes.number.isRequired,
        fields: PropTypes.arrayOf(
            PropTypes.shape({
                fieldUid: PropTypes.string.isRequired,
                prompt: PropTypes.string.isRequired,
                isRequired: PropTypes.bool,
                value: PropTypes.string,
                fieldType: PropTypes.oneOf(['text', 'textarea', 'dropdown']),
                options: PropTypes.array,
                formatRegex: PropTypes.string,
                maxLength: PropTypes.number
            })
        ).isRequired
    }).isRequired,
    onNext: PropTypes.func.isRequired
};

export default RepairInformationStep;
