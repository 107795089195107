import React, {createContext, useState} from 'react';

const StepperContext = createContext(null);

const StepperProvider = ({children, initialStep = 1}) => {
    const [currentStep, setCurrentStep] = useState(initialStep);
    const [visitedSteps, setVisitedSteps] = useState([]);

    const markVisited = (step) => {
        const updatedVisitedSteps = [...visitedSteps, step];
        setVisitedSteps(updatedVisitedSteps);
    };

    const moveNext = () => setCurrentStep((prevStep) => prevStep + 1);

    const goPrevious = () => {
        if (currentStep > 1) {
            setCurrentStep((prevStep) => prevStep - 1);
        }
    };

    const jumpToStep = (step) => setCurrentStep(step);

    const stepper = {
        currentStep,
        moveNext,
        goPrevious,
        visitedSteps,
        markVisited,
        jumpToStep,
    };

    return (
        <StepperContext.Provider value={stepper}>
            {children}
        </StepperContext.Provider>
    );
};

export {StepperProvider, StepperContext};
