import { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

export const useFacilityAddresses = () => {
    const facilityId = useSelector(state => state.user.facility.facilityId);
    const [shippingAddresses, setShippingAddresses] = useState(null);
    const [billingAddresses, setBillingAddresses] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (facilityId && (shippingAddresses === null || billingAddresses === null)) {
            axios.post(`/ShoppingService/api/v1/company/addresses/${facilityId}`)
                .then(response => {
                    const { shippingAddresses, billingAddresses } = response.data;
                    setShippingAddresses(shippingAddresses);
                    setBillingAddresses(billingAddresses);
                    setLoading(false);
                })
                .catch(error => {
                    console.error('Error loading addresses:', error);
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }

        return () => {}
    }, [facilityId, shippingAddresses, billingAddresses]);

    const memoizedAddresses = useMemo(() => ({
        shippingAddresses,
        billingAddresses,
        loading
    }), [shippingAddresses, billingAddresses, loading]);

    return memoizedAddresses;
};
