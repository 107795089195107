import React, { useEffect, useState } from "react";
import { Button, TextField, TextFieldSelect } from "@partssourceinc/react-ui-core/components";
import styled from "styled-components";
import { useStepper } from "../../../components/Stepper/hooks/useStepper";
import { getFormattedPhoneNumber, stripAwayPhoneNumberSymbols } from 'utility';
import axios from 'axios';
import { useSelector } from "react-redux";

const Divider = styled.div`
    border-bottom: 1px solid gray;
    margin-top: 30px;
`;

export function ContactInformationStep(props) {

  const { moveNext } = useStepper();
  const { onNext, data } = props;

  const user = useSelector(state => state.user);
  const facilityId = user.facility.facilityId;

  const [formState, setFormState] = useState(data);
  const [requestors, setRequestors] = useState([]);
  const [errors, setErrors] = useState({});


  useEffect(() => {
    let canceled = false;

    const getCompanyUsers = async () => {
      try {
        const response = await axios.get(`/ShoppingService/api/v1/company/contacts/${facilityId}`);
        if (!canceled) {
          setRequestors(response.data);
        }
      } catch (error) {
        if (!canceled) {
          console.error('Error fetching company users:', error);
        }
      }
    };

    if (facilityId) {
      getCompanyUsers();
    }

    return () => {
      canceled = true;
    };
  }, [facilityId]);

  useEffect(() => {
    onContactChange(user.info.contactId);

    return () => { }
  }, [requestors])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const onContactChange = (contactId) => {
    const contact = requestors?.find(x => x.contactId === contactId);
    setFormState({ ...formState, ...contact })
  }

  const validate = () => {
    let tempErrors = {};
    if (!formState.contactId) tempErrors.contactId = "Main Contact is required";
    // if (!formState.phoneNumber) tempErrors.phoneNumber = "Phone Number is required";
    // if (!formState.email) tempErrors.email = "E-Mail Address is required";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validate()) {
      onNext(data.index, formState);
      moveNext();
    }
  };

  return (
    <div className="col-xs-12">
      <div className="col-xs-12" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div className="col-xs-12" style={{ textAlign: 'left' }}>
          <TextFieldSelect
            id="contactId"
            name="contactId"
            keyField="contactId"
            valueField="fullName"
            listItems={requestors ?? []}
            label="Main Contact"
            placeHolder="Main Contact"
            selectedItem={formState ?? {}}
            onSelect={data => onContactChange(data.contactId)}
            showErrorMessage={!!errors.contactId}
            errorMessage={errors.contactId}
            disableClear
            tabIndex={0}
          />

          {formState?.phoneNumber ? <TextField
            id="phoneNumber"
            name="phoneNumber"
            tabIndex={1}
            label="Phone Number"
            readOnly={true}
            placeholder="Phone Number"
            text={getFormattedPhoneNumber(formState.phoneNumber)}
            onChange={(c, d) => setFormState({ ...formState, ...{ phoneNumber: stripAwayPhoneNumberSymbols(d.value) } })}
            showErrorMessage={!!errors.phoneNumber}
            errorMessage={errors.phoneNumber}
          /> : null}

          {formState?.email ? <TextField
            id="email"
            name="email"
            tabIndex={2}
            label="E-Mail Address"
            placeholder="E-Mail Address"
            text={formState.email}
            readOnly={true}
            onChange={handleChange}
            showErrorMessage={!!errors.email}
            errorMessage={errors.email}
          /> : null }
        </div>
      </div>

      <div className="row">
        <div className="col-xs-12">
          <Divider />
          <Button onClick={handleSubmit} style={{ float: 'right', marginTop: 30 }}>
            {formState.nextIsPreview ? 'Preview Repair Quote' : 'Next'}
          </Button>                </div>
      </div>
    </div>
  );
}
