import React, { useState } from "react";
import { Button, Checkbox } from "@partssourceinc/react-ui-core/components"
import {formatMoney} from 'utility'
import styled from "styled-components";

const Divider = styled.div`
    border-bottom: 1px solid gray;
    width: 100%;
    margin-top: 30px;
`;

export function EvaluationFeeStep(props) {
    const {onPreview, data} = props;
    const [formState, setFormState] = useState(data);

    const onHandlePreview = () => {
        onPreview(data.index, formState);
    }
    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'column', width: 360, textAlign: 'left' }}>
                <div>
                    <div>An Evaluation Fee of <strong>{formatMoney(formState.evaluationFee, false)}</strong> will be charged if the device is returned unrepaired.</div>
                    <Checkbox id="acceptedRepairEvalFee"
                        label="I Agree to the Fee"
                        onChange={e =>  setFormState({ ...formState, acceptedRepairEvalFee: e.checked })}
                        checked ={formState.acceptedRepairEvalFee}
                        className="checkbox" />
                </div>
                <Divider />
            </div>
            <Button 
                disabled={!formState.acceptedRepairEvalFee} 
                onClick={onHandlePreview} 
                style={{ float: 'right', marginTop: 30 }}>
                    Preview Repair Quote
            </Button>
        </div>

    )
}